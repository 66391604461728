import './app.css'

function App() {
  return (
    <div className='app'>
      <h1>
        Motobum Tech provides catered digital solutions and entertainment for
        motorcycle enthusiasts through Squidlyfe, MotoHoes, and MotoGX.
      </h1>
      <div className='presentation'>
        <img
          className='logo'
          alt='logo for motogx.com'
          src='https://static.motogx.com/motogx-logo-zomg.svg'
        />
        <img
          className='logo'
          alt='logo for motohoes.com'
          src='https://static.motohoes.com/motohoes-logo-zomg.svg'
        />
        <img
          className='logo'
          alt='logo for squidlyfe.com'
          src='https://static.squidlyfe.com/squidlyfe-logo-zomg.svg'
        />
      </div>
    </div>
  )
}

export default App
